<template>
	<div class="clients">
		<h1>Developer Settings</h1>
		<div>
			<button class="btn-close" @click.prevent="this.$store.dispatch('client/generateTestNodes')">Generate tree do not click</button>
			<!-- <button @click.prevent="this.$store.dispatch('client/resetData')">Reset data</button> -->
			<button class="btn-primary btn-primary--outline"><a href="/api/auth/google?client=keywordPlanner">Authenticate Keyword Search</a></button>
		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {

		}
	},
	components: {

	},
	methods: {
    
	},
}
</script>
